<template>
  <div class="index">
    <div class="content-box">
      <div class="search-box">
        <img
          class="search-img"
          src="@/assets/realTimeInfo/banner2.png"
          alt=""
        />
      </div>
      <div class="box">
        <div class="detail-info">
          <div class="info-address">
            <span>当前位置：</span>
            <span>首页</span>
            <img class="title-icon" src="@/assets/realTimeInfo/ic_1.png" />
            <span>信息资讯</span>
            <img class="title-icon" src="@/assets/realTimeInfo/ic_1.png" />
            <span>{{ dataForm.type | getType }}详情</span>
          </div>
          <div class="info-title">
            <div class="info-name">{{ dataForm.name }}</div>
            <div class="info-views">
              <span class="viewsNum">阅读量：{{ dataForm.read_num }}</span>
              <span>发布时间：{{ dataForm.date_creation }}</span>
            </div>
          </div>
          <div class="abstract">简介摘要：{{ dataForm.remark }}</div>
          <div class="info-detail" v-html="dataForm.content"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Api from "@/api";
export default {
  components: {},
  data() {
    return {
      searchForm: {
        fk_news_id: "",
      },
      dataForm: {},
    };
  },
  mounted() {
    if (this.$route.query.id) {
      this.searchForm.fk_news_id = this.$route.query.id;
    }
    this.initPage();
  },
  filters: {
    getType(type) {
      switch (type) {
        case 1:
          return "公告通知";
        case 2:
          return "会议发布";
        case 3:
          return "工作动态";
        default:
          break;
      }
    },
  },
  methods: {
    initPage() {
      Api.NewsDetail(this.searchForm).then((res) => {
        if (res.status == 1) {
          this.dataForm = res.data;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-input__inner {
  height: 64px;
  border: none;
}
.index {
  width: 100%;
  background: url("../../assets/realTimeInfo/banner.png") no-repeat;
  background-position: center top;
  background-color: #f5f5f5;

  .content-box {
    width: 1200px;
    margin: 0 auto;
    .search-box {
      padding-top: 30px;
      width: 603px;
      display: flex;
      flex-direction: column;
      .search-img {
        width: 60%;
        height: auto;
      }
    }
    .box {
      width: 100%;
      padding: 60px 0 73px;
    }
    .detail-info {
      width: 100%;
      background: #ffffff;
      border-radius: 10px;
      z-index: 10;
      position: relative;
      line-height: 180%;
      .info-address {
        width: 100%;
        padding: 26px 32px;
        display: flex;
        align-items: center;
        font-size: 18px;
        font-weight: 400;
        color: #000000;
        border-bottom: 1px solid #eeeeee;
        .title-icon {
          width: 14px;
          height: 14px;
          margin: 0 5px;
        }
      }
      .info-title {
        width: 100%;
        padding: 48px 0 30px;
        box-sizing: border-box;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        .info-name {
          font-size: 24px;
          font-weight: 400;
          color: #000000;
          padding-bottom: 20px;
        }
        .info-views {
          display: flex;
          align-items: center;
          font-size: 14px;
          font-weight: 400;
          color: #999999;
          .viewsNum {
            padding-right: 20px;
          }
        }
      }
      .abstract {
        width: calc(100% - 100px);
        margin: 0 50px;
        background: #eeeeee;
        padding: 22px 38px;
        box-sizing: border-box;
        font-size: 14px;
        font-weight: 400;
        color: #959595;
        line-height: 20px;
      }
      .info-detail {
        width: 100%;
        padding: 40px 50px;
        box-sizing: border-box;
      }
    }
  }
}
</style>
